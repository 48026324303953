/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BoardInfo = {
  id: string;
  displayName: string;
  validationInfo: string;
  status: BoardInfo.status;
};

export namespace BoardInfo {

  export enum status {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
  }


}

