/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressInfo } from '../models/AddressInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PersonApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Save a person address
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public savePersonAddress(
    requestBody: {
      address: AddressInfo;
    },
  ): CancelablePromise<{
    success: boolean;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/person/save-address',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

}
