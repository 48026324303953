/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AdminApi } from './services/AdminApi';
import { CompanyApi } from './services/CompanyApi';
import { DoctorApi } from './services/DoctorApi';
import { PersonApi } from './services/PersonApi';
import { ProfessionApi } from './services/ProfessionApi';
import { ProfessionalApi } from './services/ProfessionalApi';
import { SpecialtyApi } from './services/SpecialtyApi';
import { SupApi } from './services/SupApi';
import { SysApi } from './services/SysApi';
import { UserApi } from './services/UserApi';
import { UserActionTokenApi } from './services/UserActionTokenApi';
import { UserTermApi } from './services/UserTermApi';
import { WorkflowApi } from './services/WorkflowApi';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

  public readonly admin: AdminApi;
  public readonly company: CompanyApi;
  public readonly doctor: DoctorApi;
  public readonly person: PersonApi;
  public readonly profession: ProfessionApi;
  public readonly professional: ProfessionalApi;
  public readonly specialty: SpecialtyApi;
  public readonly sup: SupApi;
  public readonly sys: SysApi;
  public readonly user: UserApi;
  public readonly userActionToken: UserActionTokenApi;
  public readonly userTerm: UserTermApi;
  public readonly workflow: WorkflowApi;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'http://localhost:3000/api/v1',
      VERSION: config?.VERSION ?? '0.0.1',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.admin = new AdminApi(this.request);
    this.company = new CompanyApi(this.request);
    this.doctor = new DoctorApi(this.request);
    this.person = new PersonApi(this.request);
    this.profession = new ProfessionApi(this.request);
    this.professional = new ProfessionalApi(this.request);
    this.specialty = new SpecialtyApi(this.request);
    this.sup = new SupApi(this.request);
    this.sys = new SysApi(this.request);
    this.user = new UserApi(this.request);
    this.userActionToken = new UserActionTokenApi(this.request);
    this.userTerm = new UserTermApi(this.request);
    this.workflow = new WorkflowApi(this.request);
  }
}

